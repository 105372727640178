/* eslint-disable camelcase */
import dayjs, { Dayjs } from 'dayjs'

import { createErrorsHandlers } from '../../utils'
import { fetchApi } from '../fetchApi'
import { getUtcString } from './events'

export enum BookingFilterOptions {
  ALL = 'all',
  PAST = 'past',
  UPCOMING = 'upcoming'
}

interface RemoteBooking {
  id: number
  appointment_id: number
  subject_id: number
  title: string
  start_datetime: string
  end_datetime: string
  timezone: string
  study: {
    uuid: string
    name?: string
    reference_number?: string
  }
  first_name: string
  last_name: string
  photo_thumbnail: string
  photo: string
  datacapt_id: string
  project_tests?: {
    project_name: string
    project_uuid: string
    id: string
  }[]
}

export interface Booking {
  id: number
  title: string
  startDate: Dayjs
  endDate: Dayjs
  startTime: string
  endTime: string
  timezone: string
  study: {
    uuid: string
    name?: string
    reference?: string
  }
  firstName: string
  lastName: string
  photoThumbnail: string
  photo: string
  subjectId: string
  duration: number
  past: boolean
  tests?: {
    projectName: string
    projectId: string
    testId: string
  }[]
}

const parseRemoteBooking = (remoteBooking: RemoteBooking): Booking => {
  const startDate = dayjs(remoteBooking.start_datetime)
  const endDate = dayjs(remoteBooking.end_datetime)
  return {
    id: remoteBooking.id,
    title: remoteBooking.title,
    startDate,
    endDate,
    timezone: remoteBooking.timezone,
    study: {
      uuid: remoteBooking.study?.uuid,
      name: remoteBooking.study?.name,
      reference: remoteBooking.study?.reference_number
    },
    firstName: remoteBooking.first_name,
    lastName: remoteBooking.last_name,
    photoThumbnail: remoteBooking.photo_thumbnail,
    photo: remoteBooking.photo,
    subjectId: remoteBooking.datacapt_id,
    startTime: startDate.format('HH:mm'),
    endTime: endDate.format('HH:mm'),
    duration: endDate.diff(startDate, 'minutes'),
    past: endDate.isBefore(dayjs()),
    tests: remoteBooking.project_tests
      ? remoteBooking.project_tests.map(test => ({
          projectName: test.project_name,
          projectId: test.project_uuid,
          testId: test.id
        }))
      : []
  }
}

interface FetchBookingsOptions {
  startDate?: Dayjs
  endDate?: Dayjs
  options?: {
    limit?: number
    offset?: number
    search?: string
    filter?: BookingFilterOptions
  }
}

interface FetchBookingsResponse {
  count: number
  next: number
  previous: number
  results: RemoteBooking[]
}

interface FetchBookingsResponseHandlers {
  onSuccess?: ({ bookings, allBookingsCount }: { bookings: Booking[]; allBookingsCount: number }) => void
  onRequestError?: (code: number) => void
}

export const fetchBookings = (
  { startDate, endDate, options }: FetchBookingsOptions,
  responseHandlers?: FetchBookingsResponseHandlers
) => {
  const query = {
    end_datetime__gte: getUtcString(startDate),
    start_datetime__lte: getUtcString(endDate),
    search: options.search,
    filter_type: options.filter,
    limit: options.limit,
    offset: options.offset
  }

  const { req, cancel } = fetchApi.get<FetchBookingsResponse>('calendar/bookings', query)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchBookingsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess({
        bookings: body.results.map(parseRemoteBooking),
        allBookingsCount: body.count
      })
    }
  })

  return cancel
}
