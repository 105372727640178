import { createErrorsHandlers, prepareSorter } from '../../utils'
import { SorterOrder } from '../SorterOrder'
import { fetchApi } from '../fetchApi'
import { RemoteStudyAuditTrail, StudyAuditTrail, parseRemoteStudyTrail } from './StudyAuditTrail'
import { RemoteSystemAuditTrail, SystemAuditTrail, parseRemoteSystemTrail } from './SystemAuditTrail'

export interface AuditTrailsSorter {
  field: keyof (SystemAuditTrail & StudyAuditTrail)
  order: SorterOrder
}

const sorterFields = {
  userName: ['user_name'],
  eventType: ['event_type'],
  actionType: ['action_type'],
  datacaptSubjectId: ['subject_id_field']
}

interface FetchAuditTrailsResponse<T> {
  count: number
  next: number
  previous: number
  results: T[]
}

interface FetchSystemAuditTrailsOptions {
  options?: {
    limit?: number
    offset?: number
    sorter?: AuditTrailsSorter
    filters?: {
      user?: string[]
      action?: string[]
      event?: string[]
    }
  }
}

interface FetchSystemAuditTrailsResponseHandlers {
  onSuccess?: (options: { auditTrails: SystemAuditTrail[]; allAuditTrailsCount: number }) => void
  onRequestError?: (code: number) => void
}

export const fetchSystemAuditTrails = (
  { options }: FetchSystemAuditTrailsOptions,
  responseHandlers?: FetchSystemAuditTrailsResponseHandlers
) => {
  const sorter = prepareSorter<typeof sorterFields, AuditTrailsSorter>(sorterFields, options.sorter, 'timestamp')
  const query = {
    limit: options.limit,
    offset: options.offset,
    ordering: sorter,
    user_id: options?.filters?.user,
    action_type: options?.filters?.action,
    event_type: options?.filters?.event
  }

  const { req, cancel } = fetchApi.get<FetchAuditTrailsResponse<RemoteSystemAuditTrail>>('audit_trails', query)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchSystemAuditTrailsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess({
        auditTrails: body.results.map(parseRemoteSystemTrail),
        allAuditTrailsCount: body.count
      })
    }
  })

  return cancel
}

interface FetchStudyAuditTrailsOptions {
  studyId: string
  options?: {
    limit?: number
    offset?: number
    sorter?: AuditTrailsSorter
    filters?: {
      user?: string[]
      action?: string[]
      event?: string[]
      questionId?: string[]
      measureId?: string[]
      inclusionId?: string[]
    }
  }
}

export interface FetchStudyAuditTrailsResponseHandlers {
  onSuccess?: (options: { auditTrails: StudyAuditTrail[]; allAuditTrailsCount: number }) => void
  onRequestError?: (code: number) => void
}

export const fetchStudyAuditTrails = (
  { studyId, options }: FetchStudyAuditTrailsOptions,
  responseHandlers?: FetchStudyAuditTrailsResponseHandlers
) => {
  const sorter = prepareSorter<typeof sorterFields, AuditTrailsSorter>(sorterFields, options.sorter, 'timestamp')
  const query = {
    limit: options.limit,
    offset: options.offset,
    ordering: sorter,
    user_id: options?.filters?.user,
    action_type: options?.filters?.action,
    event_type: options?.filters?.event,
    question_id: options?.filters?.questionId,
    measure_id: options?.filters?.measureId,
    inclusion_id: options?.filters?.inclusionId
  }

  const { req, cancel } = fetchApi.get<FetchAuditTrailsResponse<RemoteStudyAuditTrail>>('audit_trails/study', query, {
    studyId
  })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchStudyAuditTrailsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess({
        auditTrails: body.results.map(parseRemoteStudyTrail),
        allAuditTrailsCount: body.count
      })
    }
  })

  return cancel
}
