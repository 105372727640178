/* eslint-disable camelcase */
import dayjs, { Dayjs } from 'dayjs'

import { createErrorsHandlers } from '../../utils'
import { BackendError } from '../RequestError'
import { fetchApi } from '../fetchApi'
import { RemoteScheduleSlot, ScheduleSlot, parseRemoteScheduleSlot } from './slots'

interface RemoteScheduleVisitAvailability {
  id?: string
  start: {
    date?: string
    datetime?: string
  }
  end: {
    date?: string
    datetime?: string
  }
  is_full_day: boolean
  timezone: string
}

interface RemoteScheduleVisit {
  id: number
  order: number
  title: string
  appointment_duration: number
  center_id: number
  center_name?: string
  booking_window: number
  slots_interval: number
  slot_capacity: number
  coordinator_id: number
  payment_amount: number
  availabilities: RemoteScheduleVisitAvailability[]
  bookings_count?: number
  slots?: RemoteScheduleSlot[]
  schedule_name?: string
}

export interface ScheduleVisitAvailability {
  date: Dayjs
  startTime: string
  endTime: string
  isSameDay?: boolean
}

export interface ScheduleVisit {
  id?: number
  number: number
  title: string
  duration: number
  centerId: string
  centerName?: string
  window: number
  interval: number
  capacity: number
  coordinatorId: number
  payment: number
  timezone: string
  availability: ScheduleVisitAvailability[]
  bookingsCount?: number
  slots?: ScheduleSlot[]
  scheduleName?: string
}

export enum ScheduleType {
  Sequential = 'SEQUENTIAL',
  Open = 'OPEN'
}

export interface Schedule {
  id?: number
  studyId?: string
  title: string
  visitsCount: number
  participantsCount: number
  type: ScheduleType
  visits?: ScheduleVisit[]
  isPartiallyBooked?: boolean
  isPublished?: boolean
  study: {
    title: string
    centerName: string
  }
}

export interface RemoteSchedule {
  id?: number
  title: string
  visits_count: number
  participants_count: number
  booking_type: ScheduleType
  schedules?: RemoteScheduleVisit[]
  is_published?: boolean
  study?: {
    title: string
    center_name: string
  }
}

const parseRemoteScheduleVisitAvailability: (
  availability: RemoteScheduleVisitAvailability
) => ScheduleVisitAvailability = (availability: RemoteScheduleVisitAvailability) => ({
  date: availability.start.datetime && dayjs(availability.start.datetime),
  startTime: availability.start.datetime && dayjs(availability.start.datetime).format('HH:mm'),
  endTime: availability.end.datetime && dayjs(availability.start.datetime).format('HH:mm')
})

const parseRemoteScheduleVisit: (visit: RemoteScheduleVisit) => ScheduleVisit = (visit: RemoteScheduleVisit) => ({
  id: visit.id,
  number: visit.order,
  title: visit.title,
  duration: visit.appointment_duration,
  centerId: visit.center_id && String(visit.center_id),
  centerName: visit.center_name,
  window: visit.booking_window,
  interval: visit.slots_interval,
  capacity: visit.slot_capacity,
  coordinatorId: visit.coordinator_id,
  payment: visit.payment_amount,
  timezone: visit.availabilities?.[0]?.timezone || undefined,
  bookingsCount: visit.bookings_count,
  slots: visit.slots?.map(parseRemoteScheduleSlot),
  availability: visit.availabilities?.map(parseRemoteScheduleVisitAvailability),
  scheduleName: visit.schedule_name
})

export const parseRemoteSchedule: (schedule: RemoteSchedule) => Schedule = (schedule: RemoteSchedule) => ({
  id: schedule.id,
  title: schedule.title,
  visitsCount: schedule.visits_count,
  participantsCount: schedule.participants_count,
  type: schedule.booking_type || ScheduleType.Open,
  visits: schedule.schedules?.map(parseRemoteScheduleVisit),
  isPublished: schedule.is_published,
  study: {
    title: schedule.study?.title,
    centerName: schedule.study?.center_name
  }
})

const parseScheduleVisitAvailabilityForSave = (availability: ScheduleVisitAvailability, timezone: string) => {
  const getDateTime = (time: string) => {
    const timeArray = time.split(':').map(Number)
    return availability.date.set('hour', timeArray[0]).set('minutes', timeArray[1]).format('YYYY-MM-DDTHH:mm')
  }

  return {
    start: { datetime: getDateTime(availability.startTime) },
    end: { datetime: getDateTime(availability.endTime) },
    timezone
  }
}

const prepareScheduleForSave = (schedule: Schedule) => {
  return {
    recruitment_study: schedule.studyId,
    title: schedule.title,
    visits_count: schedule.visitsCount,
    participants_count: schedule.participantsCount,
    booking_type: schedule.type || ScheduleType.Open,
    is_published: schedule.isPublished,
    schedules: schedule.visits?.map(visit => ({
      id: visit.id,
      title: visit.title,
      order: visit.number,
      is_published: schedule.isPublished,
      organizer: visit.coordinatorId,
      center: visit.centerId && Number(visit.centerId),
      visit_payment_amount: visit.payment,
      event_capacity: visit.capacity,
      event_duration: visit.duration,
      event_interval: visit.interval || undefined,
      event_booking_window: visit.window,
      availabilities: visit.availability?.map(a => parseScheduleVisitAvailabilityForSave(a, visit.timezone))
    }))
  }
}

interface ScheduleResponseHandlers {
  onSuccess?: (scheduleId: number) => void
  onRequestError?: (code: number) => void
  onOverlappingError?: () => void
}

export const createSchedule = (schedule: Schedule, responseHandlers: ScheduleResponseHandlers) => {
  const { req, cancel } = fetchApi.post<RemoteSchedule>('visit-schedules', prepareScheduleForSave(schedule))

  req.then(({ body, error, status }) => {
    if (error) {
      createErrorsHandlers<ScheduleResponseHandlers>({}, error, responseHandlers, status)
    } else {
      responseHandlers.onSuccess(body.id)
    }
  })

  return cancel
}

export const updateSchedule = ({ studyId, ...schedule }: Schedule, responseHandlers: ScheduleResponseHandlers) => {
  const { req, cancel } = fetchApi.patch<RemoteSchedule>(
    `visit-schedules/${schedule.id}`,
    prepareScheduleForSave(schedule)
  )

  req.then(({ body, error, status }) => {
    if (error) {
      createErrorsHandlers<ScheduleResponseHandlers>(
        { [BackendError.CALENDAR_AVAILABILITY_OVERLAP]: 'onOverlappingError' },
        error,
        responseHandlers,
        status
      )
    } else {
      responseHandlers.onSuccess(body.id)
    }
  })

  return cancel
}

interface FetchSchedulesResponseHandlers {
  onSuccess?: (schedules: Schedule[]) => void
  onRequestError?: (code: number) => void
}

type FetchSchedulesResponse = { results: RemoteSchedule[] }

export const fetchSchedules = ({ studyId }: { studyId: string }, responseHandlers?: FetchSchedulesResponseHandlers) => {
  const { req, cancel } = fetchApi.get<FetchSchedulesResponse>('visit-schedules', { recruitment_study: studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchSchedulesResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.results.map(parseRemoteSchedule))
    }
  })

  return cancel
}

interface FetchAllVisitsOptions {
  options: {
    hasPaymentOrder?: boolean
    search?: string
  }
}

interface FetchAllVisitsResponse {
  results: RemoteScheduleVisit[]
  count: number
}

interface FetchAllVisitsResponseHandlers {
  onSuccess?: ({ visits, countAll }: { visits: ScheduleVisit[]; countAll: number }) => void
  onRequestError?: (code: number) => void
}

export const fetchAllVisits = (
  { options }: FetchAllVisitsOptions,
  responseHandlers?: FetchAllVisitsResponseHandlers
) => {
  const query = {
    has_payment_order: options?.hasPaymentOrder ? 1 : 0,
    search: options?.search?.toLowerCase()
  }
  const { req, cancel } = fetchApi.get<FetchAllVisitsResponse>('calendar/schedules/visits', query)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchAllVisitsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess({
        visits: body.results.map(visit => parseRemoteScheduleVisit(visit)),
        countAll: body.count
      })
    }
  })

  return cancel
}
