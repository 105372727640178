import './styles/antd-theme-overrides.less'
import './styles/fonts.less'
import './styles/styles.less'

import { ConfigProvider } from 'antd'
import 'core-js/stable'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isoWeek from 'dayjs/plugin/isoWeek'
import timezone from 'dayjs/plugin/timezone'
import updateLocale from 'dayjs/plugin/updateLocale'
import utc from 'dayjs/plugin/utc'
import React from 'react'

import { UserProvider } from './components/auth/UserContext'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(updateLocale)
dayjs.extend(duration)
dayjs.extend(isoWeek)

export const App = ({ element }) => {
  const cssVar = typeof window !== 'undefined' ? { key: 'app' } : undefined
  return (
    <ConfigProvider
      theme={{
        cssVar,
        token: {
          colorPrimary: '#3a63f3',
          colorError: '#db1400',
          colorSuccess: '#39e29c',
          colorTextDisabled: '#323343',
          controlItemBgActiveDisabled: '#f7f9fd',
          colorBgContainerDisabled: '#f7f9fd',
          colorTextPlaceholder: '#6d6e80',
          colorText: '#323343',
          colorBorder: '#d4d4e1',
          colorBorderSecondary: '#f0f1f4',
          fontFamily: 'TTCommons',
          lineHeight: '1.5715',
          controlHeight: '3.2rem',
          controlHeightLG: '5.6rem',
          fontSizeLG: '1.6rem',
          borderRadius: '0.8rem',
          colorBgMask: 'rgba(18, 18, 44, 0.57)'
        },
        hashed: false
      }}
    >
      <UserProvider>{element}</UserProvider>
    </ConfigProvider>
  )
}
