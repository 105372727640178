/* eslint-disable camelcase */
import dayjs, { Dayjs } from 'dayjs'
import { EditorState, convertToRaw } from 'draft-js'

import { createErrorsHandlers, localeFromPath, prepareSorter, renderSimpleAnswer } from '../../utils'
import { BackendError } from '../RequestError'
import { SorterOrder } from '../SorterOrder'
import { SelectionKeys } from '../exports'
import { fetchApi } from '../fetchApi'
import { RemoteFile } from '../file'
import { Condition, ConditionalLogicOperator, QuestionType, prepareConditionForSave } from '../forms'
import { ParticipantStatus, participantStatusForSaveMapping } from '../recruitment/participants'
import {
  AccountStatus,
  InternationalPhoneNumber,
  MessageType,
  parseRemotePhoneNumber,
  phoneNumberToString
} from '../subjects'

export interface SubjectInRepositoryOptions {
  subjectId: string
  firstName?: string
  lastName?: string
  email: string
  internationalPhoneNumber?: InternationalPhoneNumber
  mainCenterId?: string
  language?: string
}

interface CreateNewSubjectInRepositoryResponseHandlers {
  onSuccess?: (id: string) => void
  onRequestError?: (code: number) => void
  onSubjectIdAlreadyUsed?: () => void
  onEmailAlreadyUsed?: () => void
}

interface SubjectResponse {
  datacapt_id: string
}

export const createNewSubjectInRepository = (
  {
    subjectId,
    firstName,
    lastName,
    email,
    internationalPhoneNumber,
    mainCenterId,
    language
  }: SubjectInRepositoryOptions,
  responseHandlers?: CreateNewSubjectInRepositoryResponseHandlers
) => {
  const query = {
    datacapt_id: subjectId.toUpperCase(),
    first_name: firstName || null,
    last_name: lastName || null,
    email: email || null,
    phone: phoneNumberToString(internationalPhoneNumber) || null,
    main_center: mainCenterId,
    language: language || null
  }
  const { req, cancel } = fetchApi.post<SubjectResponse>('subject_repository/subjects', query, {})

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<CreateNewSubjectInRepositoryResponseHandlers>(
        {
          [BackendError.SUBJECT_ALREADY_EXISTS]: 'onSubjectIdAlreadyUsed',
          [BackendError.SUBJECT_EMAIL_ALREADY_TAKEN]: 'onEmailAlreadyUsed'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.datacapt_id)
    }
  })

  return cancel
}

export interface SubjectInRepositorySorter {
  field: keyof SubjectInRepository
  order: SorterOrder
}

const sorterFields = {
  id: ['datacapt_id'],
  firstName: ['first_name', 'last_name'],
  internationalPhoneNumber: ['phone'],
  creationDate: ['creation_date']
}

export type SimpleFileAnswer = { uuid: string; name: string; size: number; isFile: true }
type SimpleTextAnswer = { value: string; isFile: false }
export type SimpleAnswer = SimpleTextAnswer | SimpleFileAnswer

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseRemoteSimpleAnswer = (remoteAnswer: any) => {
  if (remoteAnswer?.uuid)
    return {
      uuid: remoteAnswer.uuid,
      name: remoteAnswer.name,
      size: remoteAnswer.size,
      isFile: true
    } as SimpleFileAnswer

  return { value: renderSimpleAnswer(remoteAnswer), isFile: false } as SimpleTextAnswer
}

export interface SubjectProfileAnswer {
  question: string
  answer: SimpleAnswer
}

export interface RemoteSubjectInRepository {
  id: number
  datacapt_id: string
  first_name: string
  last_name: string
  email: string
  phone: string
  creation_date: string
  main_center: string
  language: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  variable_answers?: Record<string, any>
  profile_answers?: {
    question: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    answer: any
  }[]
  status: SubjectStatus
  subject_account_deletion_enabled: boolean
  account_deletion_request_date?: string
  last_activity_date: string
  consent_date: string
  consent_renew_deadline: string
  photo?: string
  photo_thumbnail?: string
  account_status: AccountStatus
}

export enum SubjectStatus {
  New = 'NEW',
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Pending = 'PENDING',
  DeletionRequested = 'DELETION_REQUESTED',
  Paused = 'PAUSED'
}

export const parseRemoteSubject = (remoteSubject: RemoteSubjectInRepository) => ({
  pk: remoteSubject.id,
  id: remoteSubject.datacapt_id,
  firstName: remoteSubject.first_name,
  lastName: remoteSubject.last_name,
  email: remoteSubject.email,
  internationalPhoneNumber: parseRemotePhoneNumber(remoteSubject.phone),
  creationDate: new Date(remoteSubject.creation_date),
  language: remoteSubject.language,
  mainCenterId: remoteSubject.main_center && String(remoteSubject.main_center),
  variableAnswers: remoteSubject.variable_answers
    ? {
        ...Object.entries(remoteSubject.variable_answers).reduce(
          (acc, [key, value]) => ({ ...acc, [key]: parseRemoteSimpleAnswer(value) }),
          {}
        )
      }
    : {},
  profileAnswers: remoteSubject.profile_answers?.map(a => ({
    question: a.question,
    answer: parseRemoteSimpleAnswer(a.answer)
  })),
  status: remoteSubject.status || SubjectStatus.Pending,
  isSubjectAccountDeletionEnabled: remoteSubject.subject_account_deletion_enabled,
  accountDeletionRequestDate:
    remoteSubject.account_deletion_request_date && new Date(remoteSubject.account_deletion_request_date),
  lastActivityDate: remoteSubject.last_activity_date && new Date(remoteSubject.last_activity_date),
  consentDate: remoteSubject.consent_date && dayjs(remoteSubject.consent_date, 'YYYY-MM-DD').locale(localeFromPath()),
  consentRenewDeadline: remoteSubject.consent_renew_deadline && new Date(remoteSubject.consent_renew_deadline),
  photo: remoteSubject.photo,
  photoThumbnail: remoteSubject.photo_thumbnail,
  accountStatus: remoteSubject.account_status
})

export interface SubjectInRepository {
  pk: number // TODO: refactor, id is datacapt_id and is used in many places
  id: string
  firstName: string
  lastName: string
  email: string
  internationalPhoneNumber: InternationalPhoneNumber
  creationDate: Date
  language: string
  mainCenterId: string
  variableAnswers?: Record<string, SimpleAnswer>
  profileAnswers?: SubjectProfileAnswer[]
  status: SubjectStatus
  lastActivityDate: Date
  consentDate: Dayjs
  isSubjectAccountDeletionEnabled: boolean
  accountDeletionRequestDate: Date
  consentRenewDeadline: Date
  photo?: string
  photoThumbnail?: string
  accountStatus: AccountStatus
}

interface FetchSubjectsInRepositoryOptions {
  search?: string
  conditions?: Condition[]
  status?: {
    selections: SubjectStatus[]
    operator: ConditionalLogicOperator
  }
  limit?: number
  offset?: number
  sorter?: SubjectInRepositorySorter
}

interface FetchSubjectsInRepositoryResponse {
  count: number
  count_with_email: number
  count_with_phone: number
  count_active_with_email: number
  count_active_with_phone: number
  count_without_account: number
  next: number
  previous: number
  results: RemoteSubjectInRepository[]
  variables: Record<string, string>
}

interface FetchSubjectsInRepositorySuccessProps {
  subjects: SubjectInRepository[]
  allSubjectsCount: number
  subjectsWithEmailCount: number
  subjectsWithPhoneCount: number
  activeSubjectsWithEmailCount: number
  activeSubjectsWithPhoneCount: number
  subjectsWithoutAccountCount: number
  variables: Record<string, string>
}

interface FetchSubjectsInRepositoryResponseHandlers {
  onSuccess?: ({ subjects, allSubjectsCount, variables }: FetchSubjectsInRepositorySuccessProps) => void
  onRequestError?: (code: number) => void
}

export const fetchSubjectsInRepository = (
  { search, conditions, status, limit, offset, sorter }: FetchSubjectsInRepositoryOptions,
  responseHandlers: FetchSubjectsInRepositoryResponseHandlers
) => {
  // ending "&" is on purpose. this is post request and BE needs URL params, so we need this hack to not change whole logic
  const url = `subject_repository/subjects/list/?limit=${limit}&offset=${offset}&`
  const query = {
    search,
    ordering: prepareSorter<typeof sorterFields, SubjectInRepositorySorter>(sorterFields, sorter).join(','),
    conditions: conditions.map(prepareConditionForSave),
    status: status || undefined
  }

  const { req, cancel } = fetchApi.post<FetchSubjectsInRepositoryResponse>(url, query)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchSubjectsInRepositoryResponseHandlers>({}, error, responseHandlers, status)
    } else {
      responseHandlers.onSuccess({
        subjects: body.results.map(parseRemoteSubject),
        allSubjectsCount: body.count,
        subjectsWithEmailCount: body.count_with_email,
        subjectsWithPhoneCount: body.count_with_phone,
        activeSubjectsWithEmailCount: body.count_active_with_email,
        activeSubjectsWithPhoneCount: body.count_active_with_phone,
        subjectsWithoutAccountCount: body.count_without_account,
        variables: body.variables
      })
    }
  })

  return cancel
}

export interface DeleteSubjectFromRepositoryOptions {
  subjectId: string
}

interface DeleteSubjectFromRepositoryResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
  onCantBeDeleted?: () => void
}

export const deleteSubjectFromRepository = (
  { subjectId }: DeleteSubjectFromRepositoryOptions,
  responseHandlers?: DeleteSubjectFromRepositoryResponseHandlers
) => {
  const { req, cancel } = fetchApi.delete(`subject_repository/subjects/${subjectId}`)

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<DeleteSubjectFromRepositoryResponseHandlers>(
        {
          [BackendError.SUBJECT_CANT_BE_DELETED]: 'onCantBeDeleted'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface FetchSubjectInRepositoryResponseHandlers {
  onSuccess?: (subject: SubjectInRepository) => void
  onNotFound?: () => void
  onRequestError?: (code: number) => void
}

export const fetchSubjectInRepository = (
  { subjectId }: { subjectId: string },
  responseHandlers?: FetchSubjectInRepositoryResponseHandlers
) => {
  const { req, cancel } = fetchApi.get<RemoteSubjectInRepository>(`subject_repository/subjects/${subjectId}`)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchSubjectInRepositoryResponseHandlers>(
        {
          404: 'onNotFound'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteSubject(body))
    }
  })

  return cancel
}

interface EditSubjectInRepositoryResponseHandlers {
  onSuccess?: (subject: SubjectInRepository) => void
  onRequestError?: (code: number) => void
  onEmailAlreadyUsed?: () => void
}

export const editSubjectInRepository = (
  {
    subjectId,
    firstName,
    lastName,
    email,
    internationalPhoneNumber,
    language,
    mainCenterId
  }: SubjectInRepositoryOptions,
  responseHandlers?: EditSubjectInRepositoryResponseHandlers
) => {
  const query = {
    first_name: firstName || null,
    last_name: lastName || null,
    email: email || null,
    phone: phoneNumberToString(internationalPhoneNumber) || null,
    language: language || null,
    main_center: mainCenterId || undefined
  }
  const { req, cancel } = fetchApi.patch<RemoteSubjectInRepository>(
    `subject_repository/subjects/${subjectId}`,
    query,
    {}
  )

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<EditSubjectInRepositoryResponseHandlers>(
        {
          [BackendError.SUBJECT_EMAIL_ALREADY_TAKEN]: 'onEmailAlreadyUsed'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteSubject(body))
    }
  })

  return cancel
}

interface RemoveSubjectPhotoResponseHandlers {
  onSuccess?: (subject: SubjectInRepository) => void
  onRequestError?: (code: number) => void
}

export const removeSubjectPhoto = (
  {
    subjectId
  }: {
    subjectId: string
  },
  responseHandlers?: RemoveSubjectPhotoResponseHandlers
) => {
  const query = {
    photo: null as string,
    photo_thumbnail: null as string
  }
  const { req, cancel } = fetchApi.patch<RemoteSubjectInRepository>(
    `subject_repository/subjects/${subjectId}`,
    query,
    {}
  )

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<RemoveSubjectPhotoResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteSubject(body))
    }
  })

  return cancel
}

export interface TableVariable {
  id: number
  title: string
  variable: string
  type: QuestionType
}

interface FetchSubjectRepositoryVariableResponseHandlers {
  onSuccess?: (variables: TableVariable[]) => void
  onRequestError?: (code: number) => void
}

export const fetchSubjectRepositoryVariables = (responseHandlers: FetchSubjectRepositoryVariableResponseHandlers) => {
  const { req, cancel } = fetchApi.get<TableVariable[]>('subject_repository/variables')

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchSubjectRepositoryVariableResponseHandlers>({}, error, responseHandlers, status)
    } else {
      responseHandlers.onSuccess(body)
    }
  })

  return cancel
}

interface UpdateSubjectRepositoryVariablesResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const updateSubjectRepositoryVariables = (
  { variables }: { variables: string[] },
  responseHandlers: UpdateSubjectRepositoryVariablesResponseHandlers
) => {
  const { req, cancel } = fetchApi.patch('subject_repository/config', { table_variables: variables })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<UpdateSubjectRepositoryVariablesResponseHandlers>({}, error, responseHandlers, status)
    } else {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface UpdateSubjectRepositoryStatusOptions {
  subjectId: string
  status: SubjectStatus
}

interface UpdateSubjectRepositoryStatusResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const updateSubjectRepositoryStatus = (
  { subjectId, status }: UpdateSubjectRepositoryStatusOptions,
  responseHandlers: UpdateSubjectRepositoryVariablesResponseHandlers
) => {
  const { req, cancel } = fetchApi.patch(`subject_repository/subjects/${subjectId}/status`, { status })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<UpdateSubjectRepositoryStatusResponseHandlers>({}, error, responseHandlers, status)
    } else {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface RemoteSubjectRepositoryStats {
  total: {
    active: number
    all: number
    deletion_requested: number
    inactive: number
    new_30_days: number
    pending: number
  }
  filtered: {
    active: number
    all: number
    deletion_requested: number
    inactive: number
    new_30_days: number
    pending: number
  }
}

export interface SubjectRepositoryStats {
  all: number
  lastDays: number
  active: number
  displayed: number
  inactive: number
  pending: number
  deletionRequested: number
}

const parseRemoteSubjectRepositoryStats = (stats: RemoteSubjectRepositoryStats): SubjectRepositoryStats => ({
  all: stats?.total.all || 0,
  lastDays: stats?.total.new_30_days || 0,
  active: stats?.filtered.active || 0,
  displayed: stats?.filtered.all || 0,
  inactive: stats?.filtered.inactive || 0,
  pending: stats?.filtered.pending || 0,
  deletionRequested: stats?.filtered.deletion_requested || 0
})

interface FetchSubjectRepositoryStatsResponseHandlers {
  onSuccess?: (stats: SubjectRepositoryStats) => void
  onRequestError?: (code: number) => void
}

export const fetchSubjectRepositoryStats = (
  { search, conditions, status }: FetchSubjectsInRepositoryOptions,
  responseHandlers: FetchSubjectRepositoryStatsResponseHandlers
) => {
  const url = 'subject_repository/subjects/statistics'
  const query = {
    search,
    conditions: conditions.map(prepareConditionForSave),
    status: status || undefined
  }

  const { req, cancel } = fetchApi.post<RemoteSubjectRepositoryStats>(url, query)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchSubjectRepositoryStatsResponseHandlers>({}, error, responseHandlers, status)
    } else {
      responseHandlers.onSuccess(parseRemoteSubjectRepositoryStats(body))
    }
  })

  return cancel
}

export interface SendRepositoryMessageOptions {
  messageType: MessageType
  smsBody: string
  smsLink?: string
  emailBody: EditorState
  emailSubject: string
  subjects: SelectionKeys
}

interface SendRepositoryMessageResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const sendRepositoryMessage = (
  {
    search,
    conditions,
    status,
    subjects,
    smsBody,
    smsLink,
    emailBody,
    emailSubject,
    messageType
  }: FetchSubjectsInRepositoryOptions & SendRepositoryMessageOptions,
  responseHandlers: SendRepositoryMessageResponseHandlers
) => {
  const url = 'subject_repository/message'
  const query = {
    search,
    conditions: conditions.map(prepareConditionForSave),
    status: status || undefined,
    ...(subjects?.length ? { subjects } : undefined),
    message_body: emailBody ? JSON.stringify(convertToRaw(emailBody?.getCurrentContent())) : smsBody,
    message_link: emailBody ? undefined : smsLink,
    message_subject: emailSubject,
    message_type: messageType
  }

  const { req, cancel } = fetchApi.post(url, query)

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<SendRepositoryMessageResponseHandlers>({}, error, responseHandlers, status)
    } else {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

export const supportedSubjectRepositoryFileExtensions = ['xls', 'xlsx', 'csv']

interface RemoteImportSubjectRepositoryImport extends RemoteFile {
  skipped: RemoteSkippedSubjectImportRows
}

type RemoteSkippedSubjectImportRows = Record<string, string[] | 'ALL'>

export interface SkippedSubjectImportRow {
  subjectId: string
  values: string
}

interface SubjectRepositoryImport {
  name: string
  size: number
  skipped: SkippedSubjectImportRow[]
}

const parseRemoteSkippedSubjectImportRows = (rows: RemoteSkippedSubjectImportRows) => {
  return Object.entries(rows).map(([subjectId, skipped]) => ({
    subjectId,
    values: skipped !== 'ALL' && skipped?.join(', ')
  }))
}

const parseRemoteImportSubjectRepository = (file: RemoteImportSubjectRepositoryImport) => ({
  skipped: parseRemoteSkippedSubjectImportRows(file.skipped),
  name: file.name,
  size: file.size
})

interface ImportSubjectRepositoryFileResponseHandlers {
  onSuccess?: (file: SubjectRepositoryImport) => void
  onWrongFileError?: (rowNr?: string, element?: string) => void
  onRequestError?: (code?: number) => void
}

export const importSubjectRepositoryFile = (
  { file }: { file: File },
  responseHandlers: ImportSubjectRepositoryFileResponseHandlers
) => {
  const path = 'subject_repository/import'
  const { req, cancel } = fetchApi.postFile<RemoteImportSubjectRepositoryImport>(path, { file })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<ImportSubjectRepositoryFileResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteImportSubjectRepository(body))
    }
  })

  return cancel
}

interface ResendQuestionnairesOptions {
  subjects: SelectionKeys
}

interface ResendQuestionnairesResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const resendQuestionnaires = (
  { search, conditions, status, subjects }: FetchSubjectsInRepositoryOptions & ResendQuestionnairesOptions,
  responseHandlers: ResendQuestionnairesResponseHandlers
) => {
  const url = 'subject_repository/subjects/send_questionnaires'
  const query = {
    search,
    conditions: conditions?.map(prepareConditionForSave),
    status: status || undefined,
    ...(subjects?.length ? { subjects } : { undefined })
  }

  const { req, cancel } = fetchApi.post(url, query)

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<ResendQuestionnairesResponseHandlers>({}, error, responseHandlers, status)
    } else {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface AssignToRecruitmentOptions {
  subjects: SelectionKeys
  candidateStatus: ParticipantStatus
  centerId: string
  recruitmentId: string
  isSendingInvitations: boolean
}

interface AssignToRecruitmentResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const assignToRecruitment = (
  {
    search,
    conditions,
    status,
    subjects,
    recruitmentId,
    centerId,
    candidateStatus,
    isSendingInvitations
  }: FetchSubjectsInRepositoryOptions & AssignToRecruitmentOptions,
  responseHandlers: AssignToRecruitmentResponseHandlers
) => {
  const url = 'subject_repository/subjects/assign_to_recruitment'
  const query = {
    search,
    conditions: conditions?.map(prepareConditionForSave),
    status: status || undefined,
    ...(subjects?.length ? { subjects } : { undefined }),
    recruitment_uuid: recruitmentId,
    center: centerId,
    recruitment_status: participantStatusForSaveMapping[candidateStatus],
    send_invitations: isSendingInvitations
  }

  const { req, cancel } = fetchApi.post(url, query)

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<AssignToRecruitmentResponseHandlers>({}, error, responseHandlers, status)
    } else {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface CreateAccountsOptions {
  subjects: SelectionKeys
}

interface CreateAccountsResponse {
  errors: Record<number, number>
}

interface CreateAccountsResponseHandlers {
  onSuccess?: (errors: Record<number, number>) => void
  onSubjectAccountExists?: () => void
  onEmailExists?: () => void
  onRequestError?: (code: number) => void
}

export const createAccounts = (
  { search, conditions, status, subjects }: FetchSubjectsInRepositoryOptions & CreateAccountsOptions,
  responseHandlers: CreateAccountsResponseHandlers
) => {
  const url = 'subject_repository/subjects/accounts'
  const query = {
    search,
    conditions: conditions?.map(prepareConditionForSave),
    status: status || undefined,
    ...(subjects?.length ? { subjects } : { undefined })
  }

  const { req, cancel } = fetchApi.post<CreateAccountsResponse>(url, query)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<CreateAccountsResponseHandlers>({}, error, responseHandlers, status)
    } else {
      responseHandlers.onSuccess(body.errors)
    }
  })

  return cancel
}
