/* eslint-disable camelcase */
import { ContentState, convertFromHTML, convertFromRaw } from 'draft-js'

import { createErrorsHandlers, renderSimpleAnswer } from '../../utils'
import { StudyAuditTrail } from '../auditTrails'
import { fetchApi } from '../fetchApi'

export enum SubjectActivityType {
  Created = 'ACCOUNT_CREATION',
  StatusUpdated = 'STATUS_UPDATED',
  ContactUpdated = 'CONTACT_INFORMATION_UPDATED',
  BankAccountDetailsUpdated = 'BANK_ACCOUNT_DETAILS_UPDATED',
  CampaignSent = 'CAMPAIGN_SENT',
  Export = 'DATA_EXPORTED',
  RecruitmentNoteUpdated = 'RECRUITMENT_NOTE_UPDATED',
  RecruitmentStatusUpdated = 'RECRUITMENT_STATUS_UPDATED',
  RecruitmentApplied = 'RECRUITMENT_APPLIED',
  RecruitmentInvited = 'RECRUITMENT_INVITED',
  RecruitmentResend = 'RECRUITMENT_RESEND',
  PaymentCreated = 'PAYMENT_CREATED',
  PaymentStatusUpdated = 'PAYMENT_STATUS_UPDATED',
  PaymentConfirmed = 'PAYMENT_CONFIRMED',
  PaymentUpdated = 'PAYMENT_UPDATED',
  DataItemCreated = 'DATA_ITEM_CREATED',
  DataItemEdited = 'DATA_ITEM_EDITED',
  DataItemDeleted = 'DATA_ITEM_DELETED'
}

interface RemoteSubjectRepositoryActivity {
  created_at: string
  type: SubjectActivityType
  title?: string
  question?: string
  variable?: string
  user_name?: string
  user_email?: string
  recruitment_reference_number?: string
  details?: string
  changes?: SubjectActivityChanges
}

interface RemoteSubjectRepositoryActivityResponse {
  count: number
  next: number
  previous: number
  results: RemoteSubjectRepositoryActivity[]
}

type SubjectActivityChangeValues<T> = { old: T; new: T }

export type SubjectActivityChanges = Record<string, SubjectActivityChangeValues<number | string>>

export interface SubjectRepositoryActivity {
  date: Date
  type: SubjectActivityType
  title?: string
  question?: string
  variable?: string
  userName?: string
  userEmail?: string
  recruitmentReferenceNumber?: string
  details?: ContentState
  changes?: SubjectActivityChanges
}

export const parseRemoteSubjectRepositoryActivityDetails = (remoteDetails: string) => {
  if (!remoteDetails) return null

  try {
    return convertFromRaw(JSON.parse(remoteDetails))
  } catch {
    const blocksFromHTML = convertFromHTML(remoteDetails)
    return ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap)
  }
}

const parseRemoteSubjectRepositoryActivity = (activity: RemoteSubjectRepositoryActivity): SubjectRepositoryActivity => {
  return {
    date: activity.created_at && new Date(activity.created_at),
    type: activity.type,
    title: activity.title,
    variable: activity.variable,
    question: activity.question,
    userName: activity.user_name?.trim(),
    userEmail: activity.user_email?.trim(),
    recruitmentReferenceNumber: activity.recruitment_reference_number,
    details: parseRemoteSubjectRepositoryActivityDetails(activity.details),
    changes: activity.changes
  }
}

interface FetchSubjectRepositoryActivitiesResponseHandlers {
  onSuccess?: (activities: SubjectRepositoryActivity[], allActivitiesCount: number) => void
  onRequestError?: (code: number) => void
}

interface FetchSubjectRepositoryOptions {
  subjectId: string
  recruitmentId?: string
  options: {
    offset: number
    limit: number
  }
}

export const fetchSubjectRepositoryActivities = (
  { subjectId, recruitmentId, options }: FetchSubjectRepositoryOptions,
  responseHandlers: FetchSubjectRepositoryActivitiesResponseHandlers
) => {
  const { req, cancel } = fetchApi.get<RemoteSubjectRepositoryActivityResponse>(
    `audit_trails/subject_activities/${subjectId}`,
    {
      offset: options.offset,
      limit: options.limit,
      recruitment_id: recruitmentId,
      type: Object.values(SubjectActivityType)
    }
  )

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchSubjectRepositoryActivitiesResponseHandlers>({}, error, responseHandlers, status)
    } else {
      responseHandlers.onSuccess(body.results.map(parseRemoteSubjectRepositoryActivity), body.count)
    }
  })

  return cancel
}

const parseRemoteSubjectRepositoryQuestionAuditTrail = (
  activity: RemoteSubjectRepositoryActivity
): SubjectRepositoryQuestionAuditTrail => {
  const answer = activity.changes?.answer
  return {
    timestamp: activity.created_at && new Date(activity.created_at),
    variable: activity.variable,
    question: activity.question,
    userName: activity.user_name?.trim(),
    userEmail: activity.user_email?.trim(),
    newValue: answer?.new && renderSimpleAnswer(answer.new),
    oldValue: answer?.old && renderSimpleAnswer(answer.old)
  }
}

type SubjectRepositoryQuestionAuditTrail = Partial<StudyAuditTrail>

interface FetchSubjectRepositoryQuestionAuditTrailsOptions {
  subjectId: string
  recruitmentId?: string
  questionId?: string[]
}

export interface FetchSubjectRepositoryQuestionAuditTrailsResponseHandlers {
  onSuccess?: (options: { auditTrails: SubjectRepositoryQuestionAuditTrail[]; allAuditTrailsCount: number }) => void
  onRequestError?: (code: number) => void
}
export const fetchSubjectRepositoryQuestionAuditTrails = (
  { subjectId, questionId }: FetchSubjectRepositoryQuestionAuditTrailsOptions,
  responseHandlers: FetchSubjectRepositoryQuestionAuditTrailsResponseHandlers
) => {
  const { req, cancel } = fetchApi.get<RemoteSubjectRepositoryActivityResponse>(
    `audit_trails/subject_activities/${subjectId}`,
    { question_id: questionId }
  )

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchSubjectRepositoryQuestionAuditTrailsResponseHandlers>(
        {},
        error,
        responseHandlers,
        status
      )
    } else {
      responseHandlers.onSuccess({
        auditTrails: body.results.map(parseRemoteSubjectRepositoryQuestionAuditTrail),
        allAuditTrailsCount: body.count
      })
    }
  })

  return cancel
}
